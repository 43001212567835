import { Button, Typography, styled } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { Container } from "@mui/material";

interface AppButtonProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    color: string;
    title: string;
    link: string;
}

const SquareButton = styled(Button)({
    borderRadius: '16px',
    height: '64px',
    width: '64px',
    padding: '0',

    '& .MuiButton-startIcon': {
        margin: 'auto',
        color: 'inherit'
    },
    '& .MuiSvgIcon-root': {
        transform: 'scale(2)',
      },

});

export default function AppButton(props: AppButtonProps) {

    return (
        <>
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <SquareButton
          disableElevation
            variant="contained"
            sx={{
              backgroundColor: props.color,
            }}
            onClick={() => {
                window.open(props.link);
              }}
            startIcon={<props.icon />}
          />
          <Typography align="center" fontSize={"small"} fontWeight={"bold"} sx={{mt: "2px"}}>{props.title}</Typography>
        </Container>
        </>
      );
}
