import { Container, Grid, Paper, SvgIconTypeMap } from "@mui/material"
import StyledBanner from "../components/StyledBanner";
import banner from '../images/contactPage/banner.jpg'

import { AppButtons } from "../data/contact/buttons";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import AppButton from "../components/AppButton";

interface AppButtonProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    color: string;
    title: string;
    link: string;
}

export default function Contact() {
    return (
        <>
            <StyledBanner image={banner} description={"Let's get in touch!"} />

            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper elevation={0} sx={{ p: 8 }} >
                        <Grid container spacing={8} >
                            {AppButtons.map((button: AppButtonProps, index: number) => (
                                <Grid xs={4} sm={3} md={2} item key={index}  >
                                    <AppButton
                                        icon={button.icon}
                                        color={button.color}
                                        title={button.title}
                                        link={button.link}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
            </Container>
        </>
    );
}