import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import EmailIcon from '@mui/icons-material/Email';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ClearIcon from '@mui/icons-material/Clear';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';

interface AppButtonProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    color: string;
    title: string;
    link: string;
}

export const AppButtons: Array<AppButtonProps> = [
    {
        icon: EmailIcon,
        color: "#2196f3",
        title: "Email",
        link: "mailto:harry_hocker@icloud.com"
    }, 
    {
        icon: FilterDramaIcon,
        color: "skyBlue",
        title: "BlueSky",
        link: "https://bsky.app/profile/slimymonz.com"
    }, 
    {
        icon: SportsEsportsIcon,
        color: "#7289da",
        title: "Discord",
        link: "https://discord.com/users/178902411069489153"
    }, 
    {
        icon: GitHubIcon,
        color: "#181717",
        title: "Github",
        link: "https://github.com/SlimyMonz"
    }, 
    {
        icon: LinkedInIcon,
        color: "#0077b5",
        title: "LinkedIn",
        link: "https://www.linkedin.com/in/harry-hocker-b23b63209/"
    }, 
    {
        icon: MapsUgcIcon,
        color: "#00AFF0",
        title: "Skype",
        link: "skype:harry_hocker@icloud.com?chat"
    }, 
    {
        icon: ClearIcon,
        color: "#107C10",
        title: "Xbox",
        link: "https://account.xbox.com/en-us/profile?gamertag=khyrdantai"
    }
]

