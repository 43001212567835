import { Box, Card, CardMedia, Typography, Paper } from "@mui/material";

export default function StyledBanner(props: BannerProps) {
    return (
        <Paper style={{ position: "relative" }} sx={{ mb: 2, boxShadow: "none"}}>
            <Card sx={{ borderRadius: 0, boxShadow: "none" }}>
                <CardMedia
                    component="img"
                    image={props.image}
                    style={
                        {
                            maxHeight: "20rem",
                            height: "100%",
                            width: "100%"
                        }
                    }
                />
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        p: 2,
                        borderRadius: "45px",
                        backdropFilter: "blur(1px)"
                    }}>
                    <Typography
                        variant="h3"

                        style={{
                            color: "white",
                            fontWeight: "semibold",
                            textAlign: "center"
                        }}>
                        {props.description}
                    </Typography>
                </Box>
            </Card>
        </Paper>
    );
}