export const smoothie_c = 
`#include <stdio.h>
#include <string.h>
#include <stdlib.h>

struct item
{
    int itemID;
    unsigned long int numParts;
} item;

struct recipe
{
    unsigned int numItems;
    struct item *itemList;
    unsigned long long int totalParts;
} recipe;

char **readIngredients(unsigned long long numIngredients)
{
    char **ingredientArray = calloc(numIngredients, sizeof(char *));
    for (int i = 0; i < numIngredients; i++)
    {
        char input[21];
        scanf(" %s", input);
        unsigned int wordSize = sizeof(input);
        ingredientArray[i] = calloc(wordSize + 1, sizeof(char));
        strcpy(ingredientArray[i], input);
    }
    return ingredientArray;
}

struct recipe *readRecipe(unsigned int numItems)
{
    struct item *itemList = calloc(numItems, sizeof(struct item));
    struct recipe *recipePointer = malloc(sizeof(struct recipe));

    recipePointer->itemList = itemList;
    recipePointer->numItems = numItems;
    recipePointer->totalParts = 0;

    for (int i = 0; i < numItems; i++)
    {
        int itemID = 0;
        unsigned long int numParts = 0;

        scanf(" %d", &itemID);
        scanf(" %ld", &numParts);

        itemList[i].itemID = itemID;
        itemList[i].numParts = numParts;

        recipePointer->totalParts += recipePointer->itemList[i].numParts;
    }
    return recipePointer;
};

struct recipe **readAllRecipes(int numRecipes)
{
    struct recipe **allRecipeArrays = calloc(numRecipes, sizeof(struct recipe *));
    for (int i = 0; i < numRecipes; i++)
    {
        int input = 0;
        scanf(" %d", &input);
        allRecipeArrays[i] = readRecipe(input);
    }
    return allRecipeArrays;
}

double *calculateOrder(int numSmoothies, struct recipe **recipeList, int numIngredients)
{
    double *orderList = calloc(numIngredients, sizeof(double));

    for (int i = 0; i < numSmoothies; i++)
    {
        int index = 0;
        int weight = 0;

        scanf(" %d", &index);
        scanf(" %d", &weight);

        unsigned long long int parts = recipeList[index]->totalParts;
        double weightPerPart = (double)weight / parts;

        for (int j = 0; j < (recipeList[index]->numItems); j++)
        {
            orderList[recipeList[index]->itemList[j].itemID] += (double)weightPerPart * (recipeList[index]->itemList[j].numParts);
        }
    }
    return orderList;
}

void printOrder(char **ingredientNames, double *orderList, int numIngredients)
{
    for (int i = 0; i < numIngredients; i++)
    {
        if (orderList[i] != 0)
        {
            printf("%s  %.6f\\n", ingredientNames[i], orderList[i]);
        }
    }
}

void freeIngredients(char **ingredientList, int numIngredients)
{
    for (int i = 0; i < numIngredients; i++)
    {
        free(ingredientList[i]);
    }
    free(ingredientList);
}

void freeRecipes(struct recipe **allRecipes, int numRecipes)
{
    for (int i = 0; i < numRecipes; i++)
    {
        free(allRecipes[i]->itemList);
    }
    free(allRecipes);
}

int main()
{
    unsigned int numIngredients = 0;
    scanf(" %d", &numIngredients);
    char **ingredientNames = readIngredients(numIngredients);

    unsigned int numRecipes = 0;
    scanf(" %d", &numRecipes);
    struct recipe **recipeList = readAllRecipes(numRecipes);

    unsigned int numStores = 0;
    scanf(" %d", &numStores);
    double **allOrders = calloc(numStores, sizeof(double *));
    for (int i = 0; i < numStores; i++)
    {
        int numSmoothies = 0;
        scanf(" %d", &numSmoothies);
        allOrders[i] = calculateOrder(numSmoothies, recipeList, numIngredients);
    }

    for (int j = 0; j < numStores; j++)
    {
        printf("Store #%d:\\n", (j + 1));
        printOrder(ingredientNames, allOrders[j], numIngredients);
    }

    freeIngredients(ingredientNames, numIngredients);
    freeRecipes(recipeList, numRecipes);
    free(allOrders);

    return 0;
}`