import { Grow, Typography } from "@mui/material";

import { useEffect, useState } from "react";

const textArray: Array<string> = 
[
    "Full Stack Developer", 
    "Computer Scientist", 
    "Front-End Developer", 
    "Back-End Developer", 
    "Software Engineer",
    "Mobile App Developer",
    "Graphic Designer", 
    "Video Gamer"
]

export default function TextSwap() {
    const [textIndex, setTextIndex] = useState(Math.floor(Math.random() * textArray.length));
    const [prevIndex, setPrevIndex] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        let newIndex = Math.floor(Math.random() * textArray.length);
        while (newIndex === textIndex || newIndex === prevIndex) {
          newIndex = (newIndex + 1) % textArray.length;
        }
        setPrevIndex(textIndex);
        setTextIndex(newIndex);
      }, 3000);
      return () => clearInterval(intervalId);
    }, [textIndex, prevIndex]);
  
    return (
      <div>
        {textArray.map((text, index) => (
          <Grow key={index} in={textIndex === index} timeout={1000} style={{ transformOrigin: '50%' }}>
            <Typography
              variant="h2"
              style={{ display: textIndex === index ? 'block' : 'none', textAlign: 'center' }}
            >
              {text}
            </Typography>
          </Grow>
        ))}
      </div>
    );
  }