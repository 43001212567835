export const palindrome_hs =
`mult2 :: Num a => a -> a -> a
mult2 x y = x*y

mult3 :: Num a => a -> a -> a -> a
mult3 x y z = x*y*z

firstA :: Int -> [Int]
firstA n = [x | x <- [1..n], mod x 6 == 0 || mod x 11 == 0]

isMult6Or11 :: Int -> Bool
isMult6Or11 x = mod x 6 == 0 || mod x 11 == 0

firstB :: Int -> [Int]
firstB n = [x | x <- [1..n], isMult6Or11 x]

secondA:: Int -> [Int]
secondA n = [ x | x <- [1..n], show x == reverse (show x) && mod (read (reverse (show x))) 10 == 3]

secondB :: Int -> [Int]
secondB n = [x | x <- [1..n], isPalindromeThatStartsWithDigit3 x]

isPalindrome:: Int -> Bool
isPalindrome x = show x == reverse (show x)

beginsWith3:: Int -> Bool
beginsWith3 x = mod y 10 == 3
    where y = read (reverse (show x))

isPalindromeThatStartsWithDigit3 :: Int -> Bool
isPalindromeThatStartsWithDigit3 x = isPalindrome x && beginsWith3 x`