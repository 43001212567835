export const salon_c = 
`#include <stdio.h>
#include <stdlib.h>
#include <string.h>

#define WORD 21
#define T 1
#define F 0
#define STYLISTS 10
#define ROOT 1

typedef struct customer
{
    char name[WORD];
    char stylist[WORD];
    unsigned points;
    unsigned arrivalTime;
    unsigned startTime;
    unsigned hairTime;
    unsigned endTime;
} customer;

typedef struct stylist
{
    char name[WORD];
    unsigned size;
    struct customer **heap;

} stylist;

customer *newCustomer(void)
{
    customer *newCustomer = malloc(sizeof(customer));

    strcpy(newCustomer->name, "EMPTY");
    strcpy(newCustomer->stylist, "NONE");

    newCustomer->points = 0;
    newCustomer->arrivalTime = 0;
    newCustomer->startTime = 0;
    newCustomer->hairTime = 0;
    newCustomer->endTime = 0;

    return newCustomer;
}

stylist *newStylist(void)
{
    stylist *newStylist = malloc(sizeof(stylist));

    strcpy(newStylist->name, "EMPTY");
    newStylist->size = 0;
    newStylist->heap = malloc(sizeof(customer *));

    return newStylist;
}

void set_stylist_name(stylist *stylist, char *name)
{
    strcpy(stylist->name, name);
}

void resize_heap(stylist *stylist, unsigned size)
{
    if (size < 1)
        return;
    stylist->heap = realloc(stylist->heap, (size + 1) * sizeof(customer *));
}

void scan_customer_info(customer *customer)
{
    char name[WORD], stylist[WORD];
    unsigned arrival = 0, points = 0, hair = 0;

    scanf("%u %s %s %u %u", &arrival, name, stylist, &points, &hair);

    customer->arrivalTime = arrival;
    strcpy(customer->name, name);
    strcpy(customer->stylist, stylist);
    customer->points = points;
    customer->hairTime = hair;
}

void swapCustomers(customer **heap, unsigned index1, unsigned index2)
{
    customer *temp = heap[index1];
    heap[index1] = heap[index2];
    heap[index2] = temp;
}

unsigned higher_priority(customer *customer1, customer *customer2)
{
    unsigned points1 = customer1->points;
    unsigned points2 = customer2->points;

    if (points1 > points2)
    {
        return T;
    }
    else if (points1 < points2)
    {
        return F;
    }

    char stylist1[WORD];
    strcpy(stylist1, customer1->stylist);
    char stylist2[WORD];
    strcpy(stylist2, customer2->stylist);

    unsigned flag1 = strcmp(stylist1, "NONE");
    unsigned flag2 = strcmp(stylist2, "NONE");

    if (!flag1 && flag2)
        return T;
    if (flag1 && !flag2)
        return F;

    char name1[WORD];
    strcpy(name1, customer1->name);
    char name2[WORD];
    strcpy(name2, customer2->name);

    unsigned long strlen1 = strlen(name1);
    unsigned long strlen2 = strlen(name2);

    for (int i = 0; i < strlen1 && i < strlen2; i++)
    {
        if (name1[i] < name2[i])
            return T;
        if (name1[i] > name2[i])
            return F;
    }

    if (strlen1 < strlen2)
        return T;
    else
        return F;
}

void percolate_UP(customer **heap, unsigned max_index)
{
    if (max_index == 1 || max_index / 2 == 1)
        return;

    unsigned node = max_index, root = max_index / 2;

    if (higher_priority(heap[node], heap[root]))
    {
        swapCustomers(heap, node, root);
        percolate_UP(heap, root);
    }
}

void percolate_DOWN(customer **heap, unsigned maxIndex, unsigned index)
{
    unsigned node = index;
    unsigned left = 2 * index;
    unsigned right = 2 * index + 1;

    if (left > maxIndex)
        return;
    if (left == maxIndex)
    {
        if (higher_priority(heap[left], heap[node]))
        {
            swapCustomers(heap, left, node);
        }
        return;
    }
    unsigned swap;
    if (higher_priority(heap[right], heap[left]))
    {
        swap = right;
    }
    else
    {
        swap = left;
    }
    if (higher_priority(heap[swap], heap[node]))
    {
        swapCustomers(heap, swap, node);
        percolate_DOWN(heap, maxIndex, swap);
    }
}

unsigned choose_stylist_index(stylist **list, char *stylist_name, unsigned num_stylists)
{
    unsigned index = 0;
    if (num_stylists == 1)
        return 0;
    if (strcmp(stylist_name, "NONE") != 0)
    {
        for (int i = 0; i < num_stylists; i++)
        {
            if (strcmp(list[i]->name, stylist_name) == 0)
                return i;
        }
    }

    if (list[0]->size == 0)
        return 0;
    for (int i = 1; i < num_stylists; i++)
    {
        unsigned size1 = list[index]->size;
        unsigned size2 = list[i]->size;
        if (size2 == 0)
            return i;
        if (size1 > size2)
            index = i;
    }
    return index;
}

unsigned find_next_lowest_time(stylist **list, unsigned stylists)
{
    unsigned index = 0;
    unsigned time1 = 0, time2 = 0;

    for (int i = 1; i < stylists; i++)
    {
        customer customer1 = *list[index]->heap[ROOT];
        customer customer2 = *list[i]->heap[ROOT];

        time1 = customer1.endTime;
        time2 = customer2.endTime;

        if (time1 > time2)
            index = i;
    }
    return index;
}

void print_customer(customer *customer, char *stylist)
{
    char name[WORD];
    strcpy(name, customer->name);

    unsigned time = customer->endTime;
    unsigned hairTime = customer->hairTime;
    unsigned points = customer->points;
    unsigned calcPoints = points + (hairTime / 10);

    printf("%s %u %u %s\\n", name, time, calcPoints, stylist);
}

void add_to_stylist(stylist **list, unsigned int stylists)
{
    customer *new = newCustomer();
    scan_customer_info(new);
    unsigned next = choose_stylist_index(list, new->stylist, stylists);
    list[next]->size++;
    resize_heap(list[next], list[next]->size);
    list[next]->heap[list[next]->size] = new;
    percolate_UP(list[next]->heap, list[next]->size);
}

int main(void)
{
    unsigned num_customers = 0, num_stylists = 0;
    scanf("%u %u", &num_customers, &num_stylists);
    if (num_customers == 0 || num_stylists == 0)
        return -1;

    stylist **list = malloc(num_stylists * sizeof(stylist *));
    for (int i = 0; i < num_stylists; i++)
        list[i] = NULL;

    for (int j = 0; j < num_stylists; j++)
    {
        list[j] = newStylist();
        char name[WORD];
        scanf("%s", name);
        strcpy(list[j]->name, name);
    }

    for (int k = 0; k < num_customers; k++)
    {
        add_to_stylist(list, num_stylists);
    }

    for (int l = 0; l < num_stylists; l++)
    {
        unsigned start = list[l]->heap[ROOT]->arrivalTime;
        unsigned hair = list[l]->heap[ROOT]->hairTime;
        list[l]->heap[ROOT]->endTime = (start + hair);
    }

    for (int m = 0; m < num_customers; m++)
    {
        unsigned next = find_next_lowest_time(list, num_stylists);
        stylist *stylist = list[next];
        customer **heap = stylist->heap;
        customer *root = heap[ROOT];
        unsigned time = root->endTime;

        print_customer(root, stylist->name);
        swapCustomers(heap, ROOT, stylist->size);
        free(heap[stylist->size]);
        stylist->size--;
        resize_heap(list[next], stylist->size);

        if (stylist->size == 0)
        {
            free(heap);
            free(stylist);
            for (int n = next + 1; n < num_stylists; n++)
            {
                list[n - 1] = list[n];
            }
            num_stylists--;
            continue;
        }
        percolate_DOWN(stylist->heap, stylist->size, ROOT);
        root = stylist->heap[ROOT];
        root->endTime = root->hairTime + time;
    }
    free(list);
    return 0;
}`