import { cProjects } from "./cIndex";
import { cppProjects } from "./cppIndex"
import { haskellProjects } from "./haskellIndex";
import { javaProjects } from "./javaIndex";

let Categories: Array<ProjectCategory> = [
  {
    title: "C",
    language: "c",
    projects: cProjects,
  },
  {
    title: "C++",
    language: "cpp",
    projects: cppProjects,
  },
  {
    title: "Haskell",
    language: "haskell",
    projects: haskellProjects,
  },
  {
    title: "Java",
    language: "java",
    projects: javaProjects,
  }
];

let AllProjects: Array<FlatProject> = [];

// instantiate AllProjects into a flattened map.
Categories.forEach((category) => {
  let lang = category.language;
  category.projects.forEach((project) => {
    let newFlat: FlatProject = {
      id: project.id,
      title: project.title,
      code: project.codePath,
      language: lang,
    };
    AllProjects.push(newFlat);
  });
});

export { AllProjects, Categories };
