export const prime_hs =
`isPrime :: Integral t => t -> Bool
isPrime n = prime n (n-1)
    where
    prime f s
        | f == 1 = False
        | s < 2 = True
        | mod f s == 0 = False
        | otherwise = prime f (s-1)

nextPrime :: Integer -> Integer
nextPrime n = prime (n+1)
    where
    prime v
       | isPrime v = v
       | otherwise = prime (v+1)


pList :: (Eq t, Num t, Monad m) => t -> Integer -> m [Integer]
pList 0 _ = return []
pList n np =
    do
      ns <- pList (n-1) (nextPrime np)
      return (np:ns)

nthPrime :: Int -> Integer
nthPrime n =  take n primes !! (n-1)

primes :: [Integer]
primes = sieve [2..]

sieve :: Integral a => [a] -> [a]
sieve (p : xs) = p : sieve [ x | x <- xs, x \`mod\` p > 0 ]

problem1 :: Int -> [Integer]
problem1 n = [nthPrime x | x <- [1, 3..n*2]]`