import { useParams } from "react-router-dom";
import { AllProjects } from "../data/code/projectIndex";
import CodeView from "../components/CodeView";

export default function SingleProject() {
  const { id } = useParams<{ id: string }>();
  const project = AllProjects.find((p: FlatProject) => p.id === id);
  if (!project) {
    return <div>Project not found</div>;
  }
  return (
    <div className="project">
      <CodeView project={project} />
    </div>
  );
};


/*
Each project should probably have some sort of explanation or media 
to go with it
*/