export const maze_java = 
`import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.util.HashMap;
import java.util.LinkedList;

class Spot {

    char value;
    int x;
    int y;
    int distance;
    boolean portal;

    Spot(char value, int x, int y, boolean portal) {
        this.value = value;
        this.x = x;
        this.y = y;
        this.portal = portal;
        this.distance = -1;
    }
}

class Portals {

    boolean used;

    LinkedList<Spot> list;

    public Portals(Spot first) {
        this.list = new LinkedList<>();
        this.list.add(first);
        this.used = false;
    }
}

class Map {

    int rows;
    int cols;

    Spot start;
    Spot end;

    Spot[][] map;

    HashMap<Character, Portals> portals;

    LinkedList<Spot> queue;

    Map(int rows, int cols) {
        this.rows = rows;
        this.cols = cols;
        this.map = new Spot[rows][cols];
        this.portals = new HashMap<>();
        this.queue = new LinkedList<>();
    }
}

public class maze {

    public static BufferedReader br = new BufferedReader(new InputStreamReader(System.in));

    public static void main(String[] args) throws IOException {

        String[] rowcol = br.readLine().split(" ");
        int rows = Integer.parseInt(rowcol[0]);
        int cols = Integer.parseInt(rowcol[1]);

        String[] string_map = read_map(rows);

        Map map = create_map(string_map, rows, cols);

        while (!map.queue.isEmpty()) {

            Spot spot = map.queue.getFirst();

            add_surrounding(map, spot);

            add_portals(map, spot);

            map.queue.removeFirst();
        }

        System.out.println(map.end.distance);
    }

    public static void add_surrounding(Map map, Spot spot) {

        int x = spot.x;
        int y = spot.y;

        int[] DX = { -1, 0, 0, 1 };
        int[] DY = { 0, -1, 1, 0 };

        for (int i = 0; i < 4; i++) {
            int dx = x + DX[i];
            int dy = y + DY[i];

            if (inbounds(dx, dy, map.rows, map.cols)) {
                Spot d_spot = map.map[dy][dx];
                enqueue(map, d_spot, spot.distance);
            }
        }
    }

    private static void add_portals(Map map, Spot spot) {

        if (spot.portal) {

            Portals portals = map.portals.get(spot.value);
            LinkedList<Spot> list = portals.list;

            if (!portals.used) {

                map.queue.addAll(portals.list);

                while (!list.isEmpty()) {
                    Spot temp = list.getFirst();

                    if (!temp.equals(spot)) {
                        temp.distance = spot.distance + 1;
                    }

                    list.removeFirst();
                }

                portals.used = true;
            }
        }
    }

    public static void enqueue(Map map, Spot spot, int distance) {

        if (spot.distance == -1) {
            spot.distance = distance + 1;
            map.queue.add(spot);
        }
    }

    public static Map create_map(String[] map, int rows, int cols) {
        Map new_map = new Map(rows, cols);

        for (int i = 0; i < rows; i++) {
            for (int j = 0; j < cols; j++) {

                Spot new_spot = new Spot(map[i].charAt(j), j, i, false);

                new_map.map[i][j] = new_spot;

                tag_spot(new_map, new_spot);
            }
        }
        return new_map;
    }

    private static void tag_spot(Map map, Spot spot) {
        switch (spot.value) {

            case '.':
                break;

            case '!':
                spot.distance = 0;
                break;
            case '*':

                map.start = spot;
                map.queue.add(spot);
                spot.distance = 0;
                break;
            case '$':

                map.end = spot;
                break;

            default: {
                spot.portal = true;

                if (map.portals.containsKey(spot.value)) {
                    map.portals.get(spot.value).list.add(spot);
                } else {

                    map.portals.put(spot.value, new Portals(spot));
                    map.portals.get(spot.value).list.add(spot);
                }
            }
        }
    }

    public static String[] read_map(int rows) throws IOException {

        String[] map = new String[rows];

        for (int i = 0; i < rows; i++) {
            map[i] = br.readLine();
        }
        return map;
    }

    public static boolean inbounds(int x, int y, int r, int c) {
        return (x >= 0 && x < c) && (y >= 0 && y < r);
    }

}
`