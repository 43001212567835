import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import CodeProjects from './pages/CodeProjects';
import SingleCodeProject from './pages/SingleProject';
import { deepPurple, pink } from '@mui/material/colors';

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: deepPurple,
          secondary: pink,
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: 'none', // Disable shadows for Paper component
              },
            },
          },
        },
      });

    // CssBaseline helps to modify the background on dark/light mode change
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline /> 
            <BrowserRouter>
                <div>
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="about" element={<About />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="code"
                            element={<CodeProjects />} />
                        <Route path='code/:id' element={<SingleCodeProject />} />
                        <Route path="*" element={
                            <div className={'main'}>
                                <h1>Nothing here!</h1>
                            </div>
                        } />
                        
                    </Routes>
                </div>
            </BrowserRouter>
            <div style={{ height: '24px' }}></div>
        </ThemeProvider>
    );
}
