import { Container, Grid } from '@mui/material';
import StyledCard from '../components/StyledCard';
import StyledBanner from '../components/StyledBanner';
import bannerImg from '../images/aboutPage/banner.jpg'
import { cardsInfo } from '../data/about/aboutInfo';

export default function About() {
    return (
        <>
            <StyledBanner image={bannerImg} description={"About Me"} />
            <AboutCardsContainer />
        </>
    );
};

function AboutCardsContainer() {
    return (
        <>
        <Container>
            <Grid container spacing={2}>
                {cardsInfo.map((card: StyledCardProps, index: number) => (
                    <StyledCard key={index} title={card.title} image={card.image} description={card.description} />
                ))}
            </Grid>
        </Container>
        </>
    );
}
