import { Stack, Typography, Container, Grid } from "@mui/material";
import { SocialButtonProps, SocialButtons } from "../data/home/buttons";
import StyledBanner from "../components/StyledBanner";
import SocialButton from "../components/SocialButton";
import TextSwap from "../components/TextSwap";

import homeimage from "../images/homePage/homeimage.jpg"

export default function Home() {
  return (
    <div>
      <StyledBanner image={homeimage} description={"Harry's Portfolio"} />
      <Typography sx={{ textAlign: 'center', opacity: '75%', scale: '85%' }}>Seattle cityscape photograph copyright Joshua Stetson</Typography>
      <Container>
        <Stack>
          <Typography
            sx={{ textAlign: 'center', mt: 4 }}
            variant="h3"
          >
            I am a
          </Typography>
          <TextSwap />
          <Container sx={{ mt: 5, mb: 5 }}>
            <Typography
              sx={{ textAlign: 'center' }}
              variant="h5"
            >
              Use the navigation bar at the top to explore.<br />
              Use the buttons below to quickly connect.
            </Typography>
          </Container>
          <SocialButtonStack />
        </Stack>
      </Container>
    </div>
  );
};

function SocialButtonStack() {
  return (
    <Grid container direction="row" spacing={2} alignItems="center"
      justifyContent="center">
      {SocialButtons.map((button: SocialButtonProps, index: number) => (
        <Grid item key={index}>
          <SocialButton icon={button.icon} title={button.title} link={button.link} />
        </Grid>
      ))}
    </Grid>
  );
}