export const calculations_hs =
`double :: IO ()
double = print (map (*2) [1,5,-18,99])

doubleN :: (Show b, Num b, Enum b) => b -> IO ()
doubleN n = print (map (*2) [1..n])

extractEven :: IO ()
extractEven = print (filter even [1..100])

triple :: IO ()
triple = print (map (*3) [1,3..77])

calcSumSquare :: IO ()
calcSumSquare = print (sum (map (^2) (filter odd [1,2,3,4,5,7,8,1,43,25,65,22])))`