import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export interface SocialButtonProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    title: string;
    link: string;
}

export const SocialButtons: Array<SocialButtonProps> = [
    {
        icon: EmailIcon,
        title: "Email",
        link: "mailto:harry_hocker@icloud.com"
    }, {
        icon: GitHubIcon,
        title: "Github",
        link: "https://github.com/SlimyMonz"
    }, {
        icon: LinkedInIcon,
        title: "LinkedIn",
        link: "https://www.linkedin.com/in/harry-hocker-b23b63209/"
    }
]

