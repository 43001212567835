import { Container } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface CodeViewProps {
  project: FlatProject
}

export default function CodeView(props: CodeViewProps) {
  return (
    <Container >
      <SyntaxHighlighter
        language={props.project.language}
        style={oneDark}
        showLineNumbers
        wrapLongLines
      >
        {props.project.code}
      </SyntaxHighlighter>
    </Container>
  );
};