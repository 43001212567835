export const lottery_c = 
`#include <stdio.h>
#include <stdlib.h>

typedef struct person
{
    int ID;
    struct person *nextPerson;
} person;

person *insertPerson(int ID)
{
    person *newPerson = calloc(1, sizeof(person));
    newPerson->ID = ID;
    newPerson->nextPerson = NULL;
    return newPerson;
}

void deletePerson(person **groupArray, person *initialPerson, int index, int flag)
{
    if (initialPerson->nextPerson == initialPerson)
        return;

    person *removePerson = initialPerson->nextPerson;
    initialPerson->nextPerson = removePerson->nextPerson;

    if (removePerson->ID == groupArray[index]->ID)
    {
        groupArray[index] = removePerson->nextPerson;
    }
    if (flag == 1)
        printf("%d\n", removePerson->ID);
    free(removePerson);
}

person *insertAllPeople(int numPeople)
{
    person *first = insertPerson(1);
    person *last = first;

    for (int i = 1; i < numPeople; i++)
    {
        last->nextPerson = insertPerson(i + 1);
        last = last->nextPerson;
    }
    last->nextPerson = first;
    return first;
}

void phaseOne(person **groupArray, int group, int numPeople, int skipNum, int threshNum)
{
    person *person = groupArray[group];

    while (numPeople > threshNum)
    {
        for (int i = 1; i < skipNum; i++)
        {
            person = person->nextPerson;
        }
        deletePerson(groupArray, person, group, 1);
        person = person->nextPerson;
        numPeople--;
    }
}

int phaseTwo(person **groupArray, int groups)
{
    int index = 0;

    for (int i = 1; i < groups; i++)
    {
        if (groupArray[i - 1]->ID == 1)
        {
            index = i - 1;
            return index;
        }
        if (groupArray[i]->ID < groupArray[i - 1]->ID)
            index = i;
    }
    return index;
}

void freeNodes(person *person, int ID)
{
    if (person->nextPerson->ID == ID)
    {
        free(person);
        return;
    }
    freeNodes(person->nextPerson, ID);
    free(person);
}

int main(void)
{
    int cases = 0;
    scanf(" %d", &cases);

    for (int i = 0; i < cases; i++)
    {
        int groups = 0;
        scanf(" %d", &groups);
        if (groups < 1)
        {
            printf("No groups. No winner.\\n\\n");
            continue;
        }
        person **groupArray = calloc(groups, sizeof(person *));

        for (int j = 0; j < groups; j++)
        {
            int numPeople = 0, skipNum = 0, threshNum = 0;
            scanf(" %d %d %d", &numPeople, &skipNum, &threshNum);
            groupArray[j] = insertAllPeople(numPeople);
            printf("Group #%d:\\n", j + 1);
            phaseOne(groupArray, j, numPeople, skipNum, threshNum);
        }
        int winner = phaseTwo(groupArray, groups);

        printf("Lottery winner is person %d from group %d.\\n\\n", groupArray[winner]->ID, winner + 1);

        for (int j = 0; j < groups; j++)
        {
            freeNodes(groupArray[j], groupArray[j]->ID);
        }
        free(groupArray);
    }
    return 0;
}`