export const multithreading_cpp: string = 
`#include <iostream>
#include <thread>
#include <vector>
#include <future>
#include <string>

long long karatsubaSequential(long long x, long long y) {
    if (x < 10 || y < 10) {
        return x * y;
    }

    int n = std::max(std::to_string(x).length(), std::to_string(y).length());
    int m = n / 2;

    long long a = x / (1LL << m);
    long long b = x % (1LL << m);
    long long c = y / (1LL << m);
    long long d = y % (1LL << m);

    long long ac = karatsubaSequential(a, c);
    long long bd = karatsubaSequential(b, d);
    long long adbc = karatsubaSequential(a + b, c + d) - ac - bd;

    return (ac * (1LL << (2 * m))) + (adbc * (1LL << m)) + bd;
}

long long karatsubaMultithreaded(long long x, long long y, int threads) {
    if (x < 10 || y < 10) {
        return x * y;
    }

    if (threads <= 1) {
        return karatsubaSequential(x, y);
    }

    int n = std::max(std::to_string(x).length(), std::to_string(y).length());
    int m = n / 2;

    long long a = x / (1LL << m);
    long long b = x % (1LL << m);
    long long c = y / (1LL << m);
    long long d = y % (1LL << m);

    auto future_ac = std::async(std::launch::async, karatsubaMultithreaded, a, c, threads / 2);
    auto future_bd = std::async(std::launch::async, karatsubaMultithreaded, b, d, threads / 2);
    auto future_adbc = std::async(std::launch::async, karatsubaMultithreaded, a + b, c + d, threads / 2);

    long long ac = future_ac.get();
    long long bd = future_bd.get();
    long long adbc = future_adbc.get() - ac - bd;

    return (ac * (1LL << (2 * m))) + (adbc * (1LL << m)) + bd;
}

int main() {
    long long x = 12345678;
    long long y = 87654321;

    int numThreads = std::thread::hardware_concurrency();
    if (numThreads == 0) {
        numThreads = 2;  // Fallback to a default number of threads
    }

    long long result = karatsubaMultithreaded(x, y, numThreads);

    std::cout << "Result: " << result << std::endl;

    return 0;
}`
