export const scholar_c =
`#include <stdio.h>
#include <stdlib.h>
#include <time.h>

void merge(long long int *array, long long int start, long long int middle, long long int end)
{
    long long int a = start, b = middle + 1, i = 0;
    long long int length = end - start + 1;
    long long int *sorted = calloc(length, sizeof(long long int));

    while (a < middle + 1 && b < end + 1)
    {
        if (array[a] <= array[b])
        {
            sorted[i] = array[a];
            a++;
        }
        else
        {
            sorted[i] = array[b];
            b++;
        }
        i++;
    }
    if (i < length)
    {
        while (a < middle + 1)
        {
            sorted[i] = array[a];
            a++;
            i++;
        }
        while (b < end + 1)
        {
            sorted[i] = array[b];
            b++;
            i++;
        }
    }
    for (i = start; i < end + 1; i++)
    {
        array[i] = sorted[i - start];
    }
    free(sorted);
}

void sort(long long int *array, long long int a, long long int z)
{
    if (!(a < z))
        return;
    long long int middle = (a + z) / 2;
    sort(array, a, middle);
    sort(array, middle + 1, z);
    merge(array, a, middle, z);
}

long long int returnNumBooks(long long int *array, long long int numPages, long long int length)
{
    long long int addPages = 0, i = 0;
    while (addPages < numPages && i < length)
    {
        addPages += array[i];
        if (addPages > numPages)
            continue;
        i++;
    }
    return i;
}

int main(void)
{
    int cases = 0;
    scanf(" %d", &cases);
    long long int answers[cases];

    for (int i = 0; i < cases; i++)
    {
        long long int numBooks = 0, numPages = 0;
        scanf(" %lld %lld", &numBooks, &numPages);
        long long int *allBookPages = calloc(numBooks, sizeof(long long int));
        for (int j = 0; j < numBooks; j++)
        {
            scanf(" %lld", &allBookPages[j]);
        }
        sort(allBookPages, 0, numBooks - 1);
        answers[i] = returnNumBooks(allBookPages, numPages, numBooks);
        free(allBookPages);
    }
    for (int j = 0; j < cases; j++)
    {
        printf("%lld\\n", answers[j]);
    }
    return 0;
}`