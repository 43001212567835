import generateID from "./objectFunctions";
import { deck_c } from "./C/deck.c";
import { lottery_c } from "./C/lottery.c";
import { salon_c } from "./C/salon.c";
import { scholar_c } from "./C/scholar.c";
import { smoothie_c } from "./C/smoothie.c";
import { wordsort_c } from "./C/wordsort.c";

let cProjects: Array<CodeProject> = [
  {
    id: "",
    title: "Binary Heap",
    codePath: salon_c
  },
  {
    id: "",
    title: "Binary Search Tree",
    codePath: wordsort_c
  },
  {
    id: "",
    title: "Dynamic Memory",
    codePath: smoothie_c
  },
  {
    id: "",
    title: "Linked Lists",
    codePath: lottery_c
  },
  {
    id: "",
    title: "Merge Sort",
    codePath: scholar_c
  },
  {
    id: "",
    title: "String Manipulation",
    codePath: deck_c
  },
];

generateID(cProjects, "c");

export { cProjects };


