export const wordsort_c =
`#include <stdio.h>
#include <stdlib.h>
#include <string.h>

typedef struct nodeData
{
    char string[100];
    int frequency;
    int depth;
} nodeData;

typedef struct treeNode
{
    struct nodeData *data;
    struct treeNode *left;
    struct treeNode *right;
} treeNode;

void search(treeNode *root, char *word)
{
    if (root == NULL)
    {
        printf("-1 -1\\n");
        return;
    }
    int compare = strcmp(word, root->data->string);
    if (compare == 0)
    {
        printf("%d %d\\n", root->data->frequency, root->data->depth);
        return;
    }
    else if (compare < 0)
    {
        search(root->left, word);
    }
    else if (compare > 0)
    {
        search(root->right, word);
    }
}

int nodeCounter(treeNode *root)
{
    if (root == NULL)
        return 0;
    int value = 1;
    value += nodeCounter(root->left);
    value += nodeCounter(root->right);
    return value;
}

treeNode *newNode(int depth, char *word)
{
    nodeData *newData = calloc(1, sizeof(nodeData));
    strcpy(newData->string, word);
    newData->frequency = 1;
    newData->depth = depth;
    treeNode *newNode = calloc(1, sizeof(treeNode));
    newNode->data = newData;
    newNode->left = NULL;
    newNode->right = NULL;
    return newNode;
}

void addToTree(treeNode *root, char *word, int depth)
{
    int depthCount = depth + 1;
    int compare = strcmp(word, root->data->string);

    if (compare < 0)
    {
        if (root->left == NULL)
            root->left = newNode(depthCount, word);
        else
            addToTree(root->left, word, depthCount);
    }
    else if (compare > 0)
    {
        if (root->right == NULL)
            root->right = newNode(depthCount, word);
        else
            addToTree(root->right, word, depthCount);
    }
    else if (compare == 0)
    {
        root->data->frequency += 1;
    }
}

void getInputs(int input, treeNode *root)
{
    for (int i = 0; i < input; i++)
    {
        int action = 0;
        char word[100];
        scanf("%d %s", &action, word);
        if (action == 1)
        {
            addToTree(root, word, 0);
        }
        else if (action == 2)
        {
            search(root, word);
        }
    }
}

void addToArray(treeNode *root, nodeData **array, int *index)
{
    if (root == NULL)
        return;
    addToArray(root->left, array, index);
    array[*index] = root->data;
    (*index) += 1;
    addToArray(root->right, array, index);
    free(root);
}

void merge(nodeData **array, long long int start, long long int middle, long long int end)
{
    long long int a = start, b = middle + 1, i = 0;
    long long int length = end - start + 1;
    nodeData **sorted = calloc(length, sizeof(nodeData *));
    while (a < middle + 1 && b < end + 1)
    {
        if ((array[a]->frequency) >= (array[b]->frequency))
        {
            sorted[i] = array[a];
            a++;
        }
        else
        {
            sorted[i] = array[b];
            b++;
        }
        i++;
    }
    if (i < length)
    {
        while (a < middle + 1)
        {
            sorted[i] = array[a];
            a++;
            i++;
        }
        while (b < end + 1)
        {
            sorted[i] = array[b];
            b++;
            i++;
        }
    }
    for (i = start; i < end + 1; i++)
    {
        array[i] = sorted[i - start];
    }
    free(sorted);
}

void sort(nodeData **array, long long int a, long long int z)
{
    if (!(a < z))
        return;
    long long int middle = (a + z) / 2;
    sort(array, a, middle);
    sort(array, middle + 1, z);
    merge(array, a, middle, z);
}

void freeMemory(nodeData **array, int numWords)
{
    for (int i = 0; i < numWords; i++)
    {
        free(array[i]);
    }
    free(array);
}

int main()
{
    int input = 0;
    scanf("%d", &input);
    int firstNum = 0;
    char firstString[100];

    do
    {
        scanf("%d %s", &firstNum, firstString);
        if (firstNum == 2)
            printf("-1 -1\\n");
        input--;
    } while (firstNum != 1);

    treeNode *root = newNode(0, firstString);
    getInputs(input, root);

    int numWords = nodeCounter(root);
    nodeData **array = calloc(numWords, sizeof(nodeData *));

    int index = 0;
    addToArray(root, array, &index);
    sort(array, 0, numWords - 1);

    for (int i = 0; i < numWords; i++)
    {
        printf("%s %d\\n", array[i]->string, array[i]->frequency);
    }

    freeMemory(array, numWords);
    return 0;
}`