import generateID from "./objectFunctions";
import { calculations_hs } from "./HASKELL/calculations.hs";
import { factors_hs } from "./HASKELL/factors.hs";
import { fibonacci_hs } from "./HASKELL/fibonacci.hs";
import { palindrome_hs } from "./HASKELL/palindrome.hs";
import { prime_hs } from "./HASKELL/prime.hs";

let haskellProjects: Array<CodeProject> = [
  {
    id: "",
    title: "Calculations",
    codePath: calculations_hs
  },
  {
    id: "",
    title: "Factors",
    codePath: factors_hs
  },
  {
    id: "",
    title: "Fibonacci",
    codePath: fibonacci_hs
  },
  {
    id: "",
    title: "Palindromes",
    codePath: palindrome_hs
  },
  {
    id: "",
    title: "Prime Numbers",
    codePath: prime_hs
  },
];

generateID(haskellProjects, "hs");

export { haskellProjects };
