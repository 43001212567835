export const politics_java = 
`import java.util.*;

public class politics {

    public static void main(String[] args) {

        try (Scanner stdin = new Scanner(System.in)) {
            int number_of_candidates = stdin.nextInt();
            int number_of_supporters = stdin.nextInt();

            while (number_of_candidates != 0 && number_of_supporters != 0) {

                LinkedHashMap<String, List<String>> list_of_candidates = new LinkedHashMap<>();

                for (int i = 0; i < number_of_candidates; i++) {
                    list_of_candidates.put(stdin.next(), new ArrayList<>());
                }
                stdin.nextLine();

                for (int j = 0; j < number_of_supporters; j++) {
                    String person = stdin.next();
                    String candidate = stdin.next();

                    if (list_of_candidates.containsKey(candidate)) {
                        List<String> temp = list_of_candidates.get(candidate);
                        temp.add(person);

                    } else {
                        list_of_candidates.put(candidate, new ArrayList<>());
                        List<String> temp = list_of_candidates.get(candidate);
                        temp.add(person);
                    }
                }

                list_of_candidates.forEach((key, value) -> {
                    for (String person : value) {
                        System.out.println(person);
                    }
                });

                number_of_candidates = stdin.nextInt();
                number_of_supporters = stdin.nextInt();
            }
        }
    }
}`