import {Button} from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

interface SBProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    title: string;
    link: string;
}

export default function SocialButton(props: SBProps) {
    return (
            <Button 
            variant="outlined"
            color="info"
            onClick={() => {
                window.open(props.link);
              }}
              startIcon={<props.icon />}
            >{props.title}</Button>
    );
}