export const tentaizu_java =
`import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;

public class tentaizu {

    final static int n = 7;
    final static int stars = 10;
    final static String STAR = "*";
    final static String EMPTY = ".";

    final static int[] DR = { -1 - n, -n, 1 - n, -1, 1, n - 1, n, n + 1 };
    final static int[] DR_LEFT = { -n, 1 - n, 1, n, n + 1 };
    final static int[] DR_RIGHT = { -1 - n, -n, -1, n - 1, n };

    public static void main(String[] args) throws IOException {

        BufferedReader br = new BufferedReader(new InputStreamReader(System.in));
        int numCases = Integer.parseInt(br.readLine());

        for (int loop = 0; loop < numCases; loop++) {

            String[][] map = new String[n][n];
            String[] map_2 = new String[n * n];

            for (int i = 0; i < n; i++) {
                String line = br.readLine();
                String[] split = line.split("");
                System.arraycopy(split, 0, map[i], 0, n);
            }

            int j = 0;
            for (int x = 0; x < n; x++) {
                for (int y = 0; y < n; y++) {
                    map_2[j] = map[x][y];
                    j++;
                }
            }
            System.out.println("Tentaizu Board #" + (loop + 1) + ":");

            find_tentaizu_2(map_2, 0, 0);
            if (loop + 1 != numCases)
                br.readLine();
        }
    }

    private static boolean find_tentaizu_2(String[] map, int k, int spot) {

        if (k == stars) {

            if (all_numbers_satisfied_2(map)) {

                print_map_2(map);

                return true;
            }
            return false;
        }

        for (int i = spot; i < n * n; i++) {

            boolean available = can_place_2(map, i);
            if (available) {
                map[i] = STAR;
                boolean solved = find_tentaizu_2(map, k + 1, i + 1);
                if (solved)
                    return true;
                map[i] = EMPTY;
            }
        }
        return false;
    }

    private static boolean all_numbers_satisfied_2(String[] map) {

        for (int i = 0; i < n * n; i++) {

            if (!is_number_2(map, i))
                continue;

            int value = Integer.parseInt(map[i]);

            int surrounding = surrounding_stars_2(map, i);

            if (value != surrounding)
                return false;
        }
        return true;
    }

    private static boolean can_place_2(String[] map, int spot) {

        if (!map[spot].equals(EMPTY))
            return false;

        int[] delta = left_middle_right(spot);

        for (int dr : delta) {

            boolean in_bounds = inBounds_2(spot + dr);
            if (in_bounds) {

                boolean number_spot = is_number_2(map, spot + dr);
                if (number_spot) {

                    int nearby = surrounding_stars_2(map, spot + dr);

                    int max = Integer.parseInt(map[spot + dr]);

                    if (nearby >= max)
                        return false;
                }
            }
        }

        return true;
    }

    private static int surrounding_stars_2(String[] map, int spot) {

        int nearby_stars = 0;
        int[] delta = left_middle_right(spot);

        for (int dr : delta) {

            if (inBounds_2(spot + dr)) {

                if (map[spot + dr].equals(STAR)) {
                    nearby_stars++;
                }
            }
        }
        return nearby_stars;
    }

    private static boolean is_number_2(String[] map, int spot) {
        return (!map[spot].equals(EMPTY) && !map[spot].equals(STAR));
    }

    private static boolean inBounds_2(int spot) {
        return (spot > -1 && spot < n * n);
    }

    private static void print_map_2(String[] map) {

        for (int i = 0; i < n * n; i++) {

            System.out.print(map[i]);

            if ((i + 1) % n == 0) {
                System.out.print("\\n");
            }
        }
        System.out.print("\\n");
    }

    private static int[] left_middle_right(int spot) {
        if (spot % n == 0)
            return DR_LEFT;
        if ((spot + 1) % n == 0)
            return DR_RIGHT;
        return DR;
    }

}`