import { Button, Card, CardContent, Divider, List, Typography, useTheme} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import tinycolor from 'tinycolor2'

export default function CategoryCard(props: CategoryCardProps) {
    const navigate = useNavigate();
    const theme = useTheme();
    const cardColor = theme.palette.mode === 'light' ? tinycolor(theme.palette.secondary.main).lighten(48).toString() : 'default';
    const dividerColor = theme.palette.mode === 'light' ? tinycolor(theme.palette.secondary.main).toString() : 'default';
    return (
        <Card sx={{ boxShadow: 'none', background: cardColor }}>
            <CardContent>
            <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>{props.category.title}</Typography>
            <Divider sx={{backgroundColor: dividerColor}} />
                <List >
                    {props.category.projects.map((project) => (
                        <Button variant="outlined" color="secondary" sx={{m:1}} key={project.id} onClick={() => navigate(`${project.id}`)} >
                            {project.title}
                        </Button>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};
