import generateID from "./objectFunctions";
import { homework1_cpp } from "./CPP/homework1.cpp";
import { homework2_cpp } from "./CPP/homework2.cpp";
import { multithreading_cpp } from "./CPP/multithreading.cpp";
import { dijkstra_cpp } from "./CPP/dijkstra.cpp";

let cppProjects: Array<CodeProject> = [
  {
    id: "",
    title: "Dijkstra's Algorithm",
    codePath: dijkstra_cpp,
  },
  {
    id: "",
    title: "Homework 1",
    codePath: homework1_cpp,
  },
  {
    id: "",
    title: "Homework 2",
    codePath: homework2_cpp,
  },
  {
    id: "",
    title: "Karatsuba's Multithreaded",
    codePath: multithreading_cpp,
  },
];

generateID(cppProjects, "cpp");

export { cppProjects };
