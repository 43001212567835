import { Card, CardContent, CardMedia, Grid, Typography, useTheme } from '@mui/material';
import tinycolor from 'tinycolor2';


export default function StyledCard(props: StyledCardProps) {
  const theme = useTheme();
  const cardColor = theme.palette.mode === 'light' ? tinycolor(theme.palette.primary.main).lighten(40).toString() : 'default';
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ boxShadow: 'none', background: cardColor }}>

        <CardMedia component="img" image={props.image} sx={{ maxHeight: 300 }} />
        <CardContent>
          <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
            {props.title}
          </Typography>
          <Typography>{props.description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
