import generateID from "./objectFunctions";
import { destroy_java } from "./JAVA/destroy.java";
import { maze_java } from "./JAVA/maze.java";
import { politics_java } from "./JAVA/politics.java";
import { poly_java } from "./JAVA/poly.java";
import { tentaizu_java } from "./JAVA/tentaizu.java";

let javaProjects: Array<CodeProject> = [
  {
    id: "",
    title: "Disjoint Set",
    codePath: destroy_java
  },
  {
    id: "",
    title: "Maze Solver",
    codePath: maze_java
  },
  {
    id: "",
    title: "Politics Sorting",
    codePath: politics_java
  },
  {
    id: "",
    title: "Karatsuba's Algorithm",
    codePath: poly_java
  },
  {
    id: "",
    title: "Tentaizu Solver",
    codePath: tentaizu_java

  },
];

generateID(javaProjects, "java");

export { javaProjects };
