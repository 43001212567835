export const factors_hs =
`isPrime :: Integral t => t -> Bool
isPrime n = prime n (n-1)
    where
    prime f s
        | f == 1 = False
        | s < 2 = True
        | mod f s == 0 = False
        | otherwise = prime f (s-1)

isqrt :: Integer -> Integer
isqrt = floor.sqrt.fromInteger

factorOf3orMod5 :: Integer -> Bool
factorOf3orMod5 n = (isPrime(isqrt n) && isqrt n * isqrt n == n) || mod n 5 == 0

problem3 :: Integer -> [Integer]
problem3 n = [x | x <- [1..n], factorOf3orMod5 x]`