// image imports
import aboutme from '../../images/aboutPage/aboutme.jpg'
import earlylife from '../../images/aboutPage/earlylife.jpg'
import studentambassador from '../../images/aboutPage/studentambassador.jpg'
import retail from '../../images/aboutPage/retail.jpg'
import spc from '../../images/aboutPage/spc.jpg'
import ucf from '../../images/aboutPage/ucf.jpg'

// text file of descriptions
import json from './aboutDescriptions.json'

const today = new Date();
const birth = new Date(1993, 6, 6); // July is 0-indexed
let age = today.getFullYear() - birth.getFullYear();

const birthMonth = birth.getMonth();
const todayMonth = today.getMonth();
const birthDate = birth.getDate();
const todayDate = today.getDate();

const isBeforeBirthMonth = todayMonth < birthMonth;
const isSameBirthMonth = todayMonth === birthMonth;
const isBeforeBirthDate = todayDate < birthDate;

if (isBeforeBirthMonth || (isSameBirthMonth && isBeforeBirthDate)) {
  age--;
}

export const cardsInfo: Array<StyledCardProps> = [
    {
        title: 'Who I Am',
        image: aboutme,
        description: json.whoIAm.replace("$age", age.toString())
    },
    {
        title: 'Early Life',
        image: earlylife,
        description: json.earlyLife
    },
    {
        title: 'Student Ambassador',
        image: studentambassador,
        description: json.studentAmbassador
    },
    {
        title: 'Retail',
        image: retail,
        description: json.retail
    },
    {
        title: 'St. Petersburg College',
        image: spc,
        description: json.stPetersburgCollege
    },
    {
        title: 'University of Central Florida',
        image: ucf,
        description: json.universityOfCentralFlorida
    }
]
