export const deck_c: string = 
`#include <stdio.h>
#include <stdlib.h>
#include <stdbool.h>
#define ARRAY_SIZE 104

bool ascendingValues(char array[], int i)
{
    if (array[i] == (array[i - 2] + 1))
        return true;
    if (array[i] == 'A' && array[i - 2] == 'M')
        return true;
    else
        return false;
}

void transformDecks(char *array)
{
    for (int i = 0; i < ARRAY_SIZE; (i += 2))
    {
        switch (array[i])
        {
        case '2':
            array[i] = 'A';
            break;
        case '3':
            array[i] = 'B';
            break;
        case '4':
            array[i] = 'C';
            break;
        case '5':
            array[i] = 'D';
            break;
        case '6':
            array[i] = 'E';
            break;
        case '7':
            array[i] = 'F';
            break;
        case '8':
            array[i] = 'G';
            break;
        case '9':
            array[i] = 'H';
            break;
        case 'T':
            array[i] = 'I';
            break;

        case 'Q':
            array[i] = 'K';
            break;
        case 'K':
            array[i] = 'L';
            break;
        case 'A':
            array[i] = 'M';
            break;
        default:
            break;
        }
        for (int i = 1; i < ARRAY_SIZE; i += 2)
        {
            switch (array[i])
            {
            case 'C':
                array[i] = '1';
                break;
            case 'D':
                array[i] = '2';
                break;
            case 'H':
                array[i] = '3';
                break;
            case 'S':
                array[i] = '4';
                break;
            default:
                break;
            }
        }
    }
}

void deckCompute(int input, char input_array[], int outputAscend[], int outputSuite[])
{

    int tempAscend = 1, tempSuite = 1;

    int j = 0;
    int index = 0;
    int eachValue;

    for (eachValue = 0; eachValue < input * ARRAY_SIZE; eachValue++)
    {
        if (j == 0)
        {
            scanf(" %s", &input_array[0]);
            scanf("%s", &input_array[52]);
            transformDecks(input_array);
        }

        if (j > 1)
        {
            if (j % 2 == 0)
            {
                if (ascendingValues(input_array, j))
                {
                    tempAscend++;
                    if (tempAscend > outputAscend[index])
                        outputAscend[index] = tempAscend;
                }
                else
                {
                    tempAscend = 1;
                }
            }
            else
            {
                if (input_array[j] == input_array[j - 2])
                {
                    tempSuite++;
                    if (tempSuite > outputSuite[index])
                        outputSuite[index] = tempSuite;
                }
                else
                {
                    tempSuite = 1;
                }
            }
        }
        if (j == (ARRAY_SIZE - 1))
        {
            if (tempAscend > outputAscend[index])
                outputAscend[index] = tempAscend;
            if (tempSuite > outputSuite[index])
                outputSuite[index] = tempSuite;
            tempAscend = 1;
            tempSuite = 1;
            j = 0;
            index++;
        }
        else
        {
            j++;
        }
    }
}

int main()
{
    int input;
    int outputSuite[25];
    int outputAscend[25];
    char input_array[ARRAY_SIZE + 1];

    do
    {
        printf("Enter the amount of decks (25 or fewer): \\n");
        scanf(" %d", &input);
        printf("\\n");
    } while (input > 25);

    printf("Enter deck data and press ENTER: \\n");

    deckCompute(input, input_array, outputAscend, outputSuite);

    printf("\\n");
    for (int k = 0; k < input; k++)
    {
        printf("%d   %d\\n", outputSuite[k], outputAscend[k]);
    }
    printf("\\n");
    return 0;
}`