import { Grid, Container } from "@mui/material";

import CategoryCard from "../components/CategoryCard";
import StyledBanner from "../components/StyledBanner";
import bannerImg from "../images/codePage/banner.png"

import { Categories } from '../data/code/projectIndex';

export default function CodeProjects() {
  return (
    <div>
      <StyledBanner image={bannerImg} description={"Coding Projects"} />
      <CategoriesContainer />
    </div>
  );
}

function CategoriesContainer() {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {Categories.map((category: ProjectCategory, index: number) => (
          <Grid item key={index} lg={3} sm={4} xs={6}>
            <CategoryCard category={category} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

