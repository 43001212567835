export const poly_java = 
`import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.util.Arrays;

public class poly {

    public static BufferedReader br = new BufferedReader(new InputStreamReader(System.in));

    public static void main(String[] args) throws IOException {

        int size = Integer.parseInt(br.readLine());
        int poly_size = (int) Math.pow(2, size);

        long[] poly1 = new long[poly_size];
        long[] poly2 = new long[poly_size];

        String[] split1 = br.readLine().split(" ");
        String[] split2 = br.readLine().split(" ");

        for (int i = 0; i < poly_size; i++) {
            poly1[i] = Integer.parseInt(split1[i]);
            poly2[i] = Integer.parseInt(split2[i]);
        }

        poly ply = new poly();
        polynomial poly_a = new polynomial(poly1);
        polynomial poly_b = new polynomial(poly2);

        polynomial total = ply.karatsuba(poly_a, poly_b);

        ply.print_all(total);
    }

    public void print_all(polynomial poly) {
        StringBuilder sb = new StringBuilder();

        for (int i = 1; i < poly.length; i++) {
            sb.append(poly.coefficient[i]).append("\\n");
        }

        System.out.print(sb);
    }

    public polynomial add(polynomial a, polynomial b) {
        long[] array = new long[a.length];

        for (int i = 0; i < a.length; i++) {
            array[i] = a.coefficient[i] + b.coefficient[i];
        }
        return new polynomial(array);
    }

    public polynomial subtract(polynomial a, polynomial b) {
        long[] array = new long[a.length];

        for (int i = 0; i < a.length; i++) {
            array[i] = a.coefficient[i] - b.coefficient[i];
        }
        return new polynomial(array);
    }

    public polynomial slowMultiply(polynomial a, polynomial b) {

        int length = a.length + b.length;
        long[] array = new long[length];

        for (int i = 0; i < a.length; i++) {
            for (int j = 0; j < b.length; j++) {
                long temp = a.coefficient[i] * b.coefficient[j];

                array[i + j + 1] += temp;

            }
        }
        return new polynomial(array);
    }

    public polynomial shift_add(polynomial z0, polynomial z1, polynomial z2) {
        int n = z0.length;
        long[] array = new long[n * 2];

        for (int i = 0; i < z0.length; i++) {
            array[i] += z0.coefficient[i];
            array[i + n / 2] += z1.coefficient[i];
            array[i + n] += z2.coefficient[i];
        }
        return new polynomial(array);
    }

    private polynomial karatsuba(polynomial a, polynomial b) {

        if (a.length <= 32) {
            return slowMultiply(a, b);
        }

        polynomial left_a = a.getLeft();
        polynomial right_a = a.getRight();
        polynomial left_b = b.getLeft();
        polynomial right_b = b.getRight();

        polynomial z0 = karatsuba(left_a, left_b);
        polynomial z2 = karatsuba(right_a, right_b);

        polynomial sum = add(z0, z2);

        polynomial aSum = add(left_a, right_a);
        polynomial bSum = add(left_b, right_b);
        polynomial mult = karatsuba(aSum, bSum);
        polynomial z1 = subtract(mult, sum);

        return shift_add(z0, z1, z2);
    }
}

class polynomial {

    public int length;
    public long[] coefficient;

    public polynomial(long[] vals) {
        this.length = vals.length;
        this.coefficient = Arrays.copyOf(vals, this.length);
    }

    public polynomial getLeft() {
        long[] left = Arrays.copyOf(this.coefficient, this.length / 2);
        return new polynomial(left);
    }

    public polynomial getRight() {
        long[] left = Arrays.copyOfRange(this.coefficient, this.length / 2, this.length);
        return new polynomial(left);
    }
}`