export const fibonacci_hs =
`fib :: (Eq t, Num t, Num a) => t -> a
fib 0 = 1
fib 1 = 1
fib n = fib (n-1) + fib (n-2)

fibCount :: (Ord t1, Num t2, Num t1, Eq t2) => t1 -> p -> t2
fibCount n c = count n 0
    where
        count f c
           | fib c > f = c
           | otherwise = count f (c+1)

endsWith3 :: Integral a => a -> a -> Bool
endsWith3 f n = (mod f 10 == 3) && (f <= n)

problem2 :: (Integral a1, Num a2) => a1 -> [a2]
problem2 n = [fib x | x <- [1..y], endsWith3 (fib x) n]
    where
        y = fibCount n 0

`