export const dijkstra_cpp: string = 
`#include <iostream>
#include <vector>
#include <queue>
#include <limits>

struct Edge {
    int dest;
    int weight;
};

struct Vertex {
    std::vector<Edge> neighbors;
    int distance;
    bool visited;
};

void dijkstra(std::vector<Vertex>& graph, int startVertex) {
    int numVertices = graph.size();
    
    for (int i = 0; i < numVertices; ++i) {
        graph[i].distance = std::numeric_limits<int>::max();
        graph[i].visited = false;
    }

    graph[startVertex].distance = 0;

    std::priority_queue<std::pair<int, int>, std::vector<std::pair<int, int>>, std::greater<std::pair<int, int>>> pq;

    pq.push({0, startVertex});

    while (!pq.empty()) {
        
        int u = pq.top().second;
        pq.pop();

        if (graph[u].visited)
            continue;

        graph[u].visited = true;

        for (const Edge& edge : graph[u].neighbors) {
            int v = edge.dest;
            int weight = edge.weight;

            
            if (!graph[v].visited && graph[u].distance + weight < graph[v].distance) {
                graph[v].distance = graph[u].distance + weight;
                pq.push({graph[v].distance, v});
            }
        }
    }
}

int main() {
    int numVertices;
    int startVertex;
    std::cout << "How many vertices?" << std::endl;
    std::cin >> numVertices;
    std::cout << "Enter the start vertex (0 to " << numVertices - 1 << "):" << std::endl;
    std::cin >> startVertex;
    std::vector<Vertex> graph(numVertices);
    std::cout << "Enter the adjacency matrix:" << std::endl;

    for (int i = 0; i < numVertices; ++i) {
        for (int j = 0; j < numVertices; ++j) {
            int weight;
            std::cin >> weight;
            if (weight != -1) {
                graph[i].neighbors.push_back({j, weight});
            }
        }
    }

    dijkstra(graph, startVertex);

    std::cout << "Distances from vertex " << startVertex << " to all other vertices:" << std::endl;
    for (int i = 0; i < numVertices; ++i) {
        std::cout << "Vertex " << i << ": " << graph[i].distance << std::endl;
    }

    return 0;
}`